import angular from "angular";

import {angularizeDirective} from "../../shared/react-in-angular.js";
import SearchBar from "./SearchBarWrapper.jsx";

export default angular.module("app.components.searchBar", []).name;

// matches <inbox-search-bar>
angularizeDirective(SearchBar, "inboxSearchBar", angular.module("app.components.searchBar"), {
  searchText: "<",
  searchService: "<",
  currentPage: "<",
  autoFillValues: "<",
});
