/* eslint-env browser */
/* eslint-disable react/prop-types */
import React from "react";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import AddProviders from "../../shared/react/AddProviders.jsx";
import useEnvironmentVariables from "../hooks/useEnvironmentVariables.jsx";
import SearchBar from "./SearchBar.jsx";

function SearchBarWrapper({
  // Props
  searchText,
  searchService,
  currentPage,
  autoFillValues,
  $scope,
}) {
  //---------------------------------------------------------------------------
  // Global Variables
  //---------------------------------------------------------------------------
  const {features} = useEnvironmentVariables();

  const setSearch = React.useCallback(
    (value) => {
      $scope.$apply(() => {
        searchService.setSearch(value);
      });
    },
    [$scope, searchService]
  );

  const searchHelper = React.useMemo(() => {
    switch (currentPage) {
      case "Studies":
        return [
          {label: "Has the words", keyword: "+", variant: "global"},
          {label: "Doesn't have", keyword: "-", variant: "global"},
          {label: "Patient", keyword: "patient", variant: "negatable"},
          {label: "Patient DoB", keyword: "dob", variant: "negatable"},
          {label: "Ordering Physician", keyword: "physician-name", variant: "negatable"},
          {label: "Device SN", keyword: "device", variant: "negatable"},
          {label: "Study ID", keyword: "study", variant: "negatable"},
          {label: "Facility Name", keyword: "facility", variant: "negatable"},
          {label: "Study Indication", keyword: "indication", variant: "negatable"},
          {label: "Billing Contract", keyword: "contract", variant: "negatable"},
          {label: "Finalized At", keyword: "finalized", variant: "relative"},
          ...(features.downgradeAuthorized
            ? [
                {
                  label: "Downgrade Authorized",
                  keyword: "is:downgradeAuthorized",
                  variant: "toggle",
                },
                {
                  label: "Meets Report Criteria",
                  keyword: "is:meetingReportCriteria",
                  variant: "toggle",
                },
              ]
            : []),
        ];
      case "Analyze":
      case "Completed":
        return [
          {label: "Has the words", keyword: "+", variant: "global"},
          {label: "Doesn't have", keyword: "-", variant: "global"},
          {label: "Patient", keyword: "patient", variant: "negatable"},
          {label: "Ordering Physician", keyword: "physician-name", variant: "negatable"},
          {label: "Device SN", keyword: "device", variant: "negatable"},
          {label: "Study ID", keyword: "study", variant: "negatable"},
          {label: "Study Type", keyword: "study-type", variant: "negatable"},
          {label: "Facility Name", keyword: "facility", variant: "negatable"},
          {label: "Item Type", keyword: "type", variant: "negatable"},
          {
            label: "Item Age",
            keyword: "age",
            variant: "inequality",
            options: [
              {id: "select", name: "-- Select Option --", value: ""},
              {id: "1h", name: "1 hour"},
              {id: "2h", name: "2 hours"},
              {id: "10h", name: "10 hours"},
              {id: "1d", name: "1 day"},
              {id: "7d", name: "7 days"},
              {id: "30d", name: "30 days"},
            ],
          },
          {label: "Event Items", keyword: "is:event", variant: "toggle"},
          {label: "Notification Items", keyword: "is:notification", variant: "toggle"},
          {label: "Report Items", keyword: "is:report", variant: "toggle"},
          {label: "Classified Events", keyword: "is:classified", variant: "toggle"},
          ...(features.algorithmClassification
            ? [
                {
                  label: "Auto-classified Events",
                  keyword: "is:auto-classified",
                  variant: "toggle",
                },
              ]
            : []),
          {label: "Items From Finalized Study", keyword: "is:from-finalized-study", variant: "toggle"},
        ];
      case "Triage":
        return [
          {label: "Has the words", keyword: "+", variant: "global"},
          {label: "Doesn't have", keyword: "-", variant: "global"},
          {label: "Device SN", keyword: "device", variant: "negatable"},
          {label: "Study ID", keyword: "study", variant: "negatable"},
          {label: "Facility Name", keyword: "facility", variant: "negatable"},
          {label: "Item Type", keyword: "type", variant: "negatable"},
        ];
      case "Reports":
        return [
          {label: "Has the words", keyword: "+", variant: "global"},
          {label: "Doesn't have", keyword: "-", variant: "global"},
          {label: "Patient", keyword: "patient", variant: "negatable"},
          {label: "Ordering Physician", keyword: "physician-name", variant: "negatable"},
          {label: "Study ID", keyword: "study", variant: "negatable"},
          {label: "Study Type", keyword: "study-type", variant: "negatable"},
          {label: "Facility Name", keyword: "facility", variant: "negatable"},
          {label: "Report Type", keyword: "type", variant: "negatable"},
          {label: "Report Number", keyword: "number", variant: "relative"},
          {label: "Technician Findings", keyword: "findings", variant: "negatable"},
          {label: "Physician Comments", keyword: "comments", variant: "negatable"},
          {label: "MDN Criteria", keyword: "is:mdn", variant: "toggle"},
        ];
      case "Icons":
        return [
          {label: "Has the words", keyword: "+", variant: "global"},
          {label: "Doesn't have", keyword: "-", variant: "global"},
        ];
      default:
        return null;
    }
  }, [currentPage, features.downgradeAuthorized, features.algorithmClassification]);

  const searchFields = React.useMemo(
    () =>
      autoFillValues.reduce((obj, val) => {
        if (!val) {
          return obj;
        }
        const [prefix, suffix] = val.split(":");
        if (suffix) {
          return {...obj, [prefix]: {...obj[prefix], [suffix]: "suffix"}};
        }
        return {...obj, [prefix]: prefix};
      }, {}),
    [autoFillValues]
  );

  //---------------------------------------------------------------------------
  // NOTE: The only reason that this file is separate from SearchBar.jsx is because of Angular.
  //  Once we switch completely from Angular to React, ConfirmProvider can be moved to an app.js so
  //  that it encapsulates the entire application. Then these two files can be combined.
  //---------------------------------------------------------------------------
  return (
    <AddProviders>
      <SearchBar
        search={searchText}
        searchHelper={searchHelper}
        setSearch={setSearch}
        autoFillValues={autoFillValues}
        searchFields={searchFields}
      />
    </AddProviders>
  );
}

export default SearchBarWrapper;
